<template>
  <div class="termgenerator">
    <m-frame
      :routeList="menuList"
      alive-include="Home"
      deleteApi="/giftcard-uninstall-removeapp.html"
      title="礼品卡"
    />
  </div>
</template>

<script>
import MFrame from '@/components/frame/Frame.vue';
export default {
  components: {
    'm-frame': MFrame,
  },
  setup() {
    const menuList = [
      {
        path: '',
        title: '操作指南',
      },
      {
        path: 'gift-list',
        title: '礼品卡列表',
      },
      {
        path: 'mail-setting',
        title: '邮件设置',
      },
      {
        path: 'gift-detail',
        title: '礼品卡明细',
      },
    ];

    return {
      menuList,
    };
  },
};
</script>

<style lang="scss"></style>
